import { makeStyles} from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@material-ui/core';
import RuleForm from './SMSRuleForm';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import settingsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 20 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' }
}));

function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    console.log (props);

    const handleClose = event => {props.setOpen (false); props.setDirectActionOpen(false) ;}
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  setDirectActionOpen = {props.setDirectActionOpen} />
            </Dialog>
        </>
    );
}



function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' , textTransform:'None'}} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant='contained' style = {{ backgroundColor : '#FF9017', color : 'floralwhite' , textTransform:'None' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};



export default function SMS (props) {

    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))

    settings= [...obj["Settings"]];
    HWLocalStorage.save (2, "previousComSettingsTab")

    const getValue= (value) => {
        switch(value){
            case "SignUp"  : return "Sign Up" ; break ;
            case "BikeVerifiedFromJpj"  : return "Bike Verified From Jpj" ;  break ;
            case "BikeVerifiedByOpsUser"  : return "Bike Verified By Ops User" ; break ;
            case "BikeRejectedByOpsUser"  : return "Bike Rejected By Ops User" ; break ;
            case "BikeApplicableForManual"  : return "Bike Applicable For Manual" ; break ;
            case "BonusPointsAwarded"  : return "Bonus Points Awarded" ; break ;
            case "VoucherAwarded"  : return "Voucher Awarded" ; break ;
            case "VoucherAwardedManually"  : return "Voucher Awarded Manually" ; break ;
            case "PointsAwardedForPurchaseByMerchant"  : return "Points Awarded For Purchase By Merchant" ; break ;
            case "EventCheckIn"  : return "Event Check In" ; break ;
            case "PointsAwardedOnCheckIn"  : return "Points Awarded On CheckIn" ; break ;
            case "PointsRedeemedForWoocommerceOrder"  : return "Points Redeemed For Woocommerce Order" ; break ;
            case "PointsAwardedForGOSOrder"  : return "Points Awarded For GOS Order" ; break ;
            case "PointsAwardedManually"  : return  "Points Awarded Manually" ; break ;
            case "PointsDeductedManually"  : return "Points Deducted Manually" ; break ;
            case "MembershipDowngraded"  : return "Membership Downgraded" ; break ;
            case "MembershipExpired"  : return "Membership Expired" ; break ;
            case "MembershipReactivated"  : return  "Membership Reactivated" ; break ;
            case "RemovedAssignedUser"  : return " Removed Assigned User" ; break ;
            case "PointsExpired"  : return "Points Expired" ; break ;
            case "BikeAssigned"  : return "Bike Assigned" ; break ;
            case "VoucherExpired" : return "Voucher Expired" ; break ;
            case "EServiceCouponExpired"  : return "EService Coupon Expired" ; break ;
            case "VoucherRedeemed"  : return "Voucher Redeemed" ; break ;
            case "EServiceCouponRedeemed"  : return "E Service Coupon Redeemed" ; break ;
             case "EmailUpdated"  : return "Email Updated" ; break ;
             case "MobileNumberUpdated"  : return "Mobile Number Updated" ; break ;
             case "PasswordUpdated"  : return "Password Updated" ; break ;
             case "ServiceRequestRaised"  : return "Service Request Raised" ; break ;
             case "UploadedMembershipCard"  : return "Uploaded Membership Card" ; break ;
             case "MembershipUpgraded"  : return "Membership Upgraded" ; break ;
        }
    }




   const classes = useStyles();
   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (0);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ openDirectAction, setOpenDirectAction ] = useState (false);
   const [ selectedRequest, setSelectedRequest ] = useState (null);
   const [ searchText, setSearchText ] = useState ('');
   const [creatorMode , setCreatorMode ] = useState(false);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ actionOpen, setActionOpen ] = useState (false);
   const [loading, setLoading ] = useState(false);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ refreshState, setRefreshState ] = useState (false);
   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);

   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };
   const handleClick = (event,id) => { openRule(); };


const checkPriorDelete = deleteObject => {
    // Check for any existing rules which may be applicable for the time being
    return true;
};
const handleEdit = (id) =>{
    setCreateMode (prev => false); 
    setRuleOpen (prev => true);
} 

const [ createOpen, setCreateOpen ] = useState (false);


const [ rulesLoading, setRulesLoading ] = useState (false);
const [ deletingRule, setDeletingRule ] = useState (null);
const [ deleteItemState , setDeleteItemState ] = useState('');

useEffect (() => {
    setRulesLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;
    var actionType = "Sms" ;
    settingsApiService.fetchAppNotification ({actionType  ,  searchText, sortModel , filterModel , paginationBookmark , fetchLimit})
    .then (response => {
        if (!response || !response.data)
            return console.log("No relevant data can be fetched");
        setRowCount(response.data.length);
        setRows (response.data.slice(paginationBookmark,response.data.length));
     
    HWMessage.show("Request Successful" , "success");
    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setRulesLoading (false); setDirectActionOpen(false);var my_element = document.getElementById("top");
    if(my_element!== null || my_element!== undefined || my_element!== '')
    {
        // my_element.scrollIntoView({ }); 

    }   

});
}, [ page, pageSize ,  refreshState , searchText , sortModel , filterModel ]);



   const deleteRule = useCallback (id => {
       console.log(id);
       if (id != null) {
           setRulesLoading (true);
           settingsApiService.deleteSpecificSMSNotification (id) 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule","error"); }
               
               console.log (response.data);
               setRowCount (rowCount - 1);
                 setRows (prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
                 setRefreshState(prev=>!prev);

               HWMessage.show("Request Successful" , "success");
           })
           .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setRulesLoading (false);setCreateOpen(false); });
       }
   });

const handleDelete = (id) => {

        deleteRule (id)
}



   const [ sortModel, setSortModel ] = useState ({});

    const columns = [
       { field : 'action',          headerName : 'Notification Type',          filterable : true,  sortable : true, flex : 3,renderCell : cellParams => <> {getValue(cellParams.row.action)} </> },
       { field : 'smsBody',          headerName : 'Content',          filterable : true,  sortable : true, flex : 4 },
       { field : 'createTime', headerName : 'Create Date', flex:2, renderCell : cellParams => {
        var date = cellParams.row.createTime;
        var dateString = date.split('T');
        var d = new Date (dateString).toLocaleDateString();

        return <span> {d} </span>
    } },

    ];


   if(settings.includes("Action") === true){
        columns.push(       { field : 'Edit',             headerName : '',             filterable : false, sortable : false, flex : 1,  renderCell : cellParams => <> <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row) ; setSelectedRuleId(cellParams.row); handleEdit(event) } } > <Edit style={{color: '#138DE8' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )
        columns.push(       { field : 'Delete',             headerName : '',             filterable : false, sortable : false, flex : 1,  renderCell : cellParams => <> <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row) ; setSelectedRuleId(cellParams.row) ; setCreateOpen(true) } } > <Delete style={{color: '#FF9017' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )
  
    }
      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();

   
   const history = useHistory ();

    return (
        <Fragment>
            <BlockUi blocking = { rulesLoading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                            SMS
                        </h4> 
                        { settings.includes("Action") === true &&  <Fab onClick = { addRule } variant = 'extended' aria-label="add" style = {{ float : 'right', marginTop : -20, background : '#FF9017', color : 'white' }}  > <GridAddIcon  /> Add SMS</Fab>}
                    </CardHeader>
                    <CardBody>  
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                <center>
                                    <DataGrid 
                                        className = { dataGridClasses.root }                                        
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { rows }
                                        rowCount = { rowCount }
                                        columns = { columns }
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                                        sortingMode="server" 
                                        onSortModelChange = { params => { 
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        } }
                                      
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        }}
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        disableExtendRowFullWidth = { false }
                                    />
                                    </center>
                            </GridItem>
                        </GridContainer>

                    </CardBody>
               
                </Card>
                <RuleDialog     
                ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                rules = { rows }
              //  setRules = { setRows }
                refreshState = { refreshState } 
                setRefreshState = { setRefreshState }
                setDirectActionOpen = {setDirectActionOpen}

            />
                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen }  dialogMessage = { 'Are you sure you want to delete this notification' } dialogTitle = { 'Confirm notification Deletion' } confirmingAction = { e => handleDelete(deleteItemState) } />

            </BlockUi>
            
        </Fragment>
    );
};